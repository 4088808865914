<template>
  <v-edit-dialog
    :return-value.sync="item"
    v-if="!statusAprovado(item)"
    persistent
    :ref="`${prefixRefNameRecDefinido}${item.idApuracao}`"
    @open="guardarValorInicial(item)"
  >
    <div >
      {{ getMoney(item.recebimentoDefinido) }}
      <v-icon class="ml-2" size="19">edit</v-icon>
    </div>
    <template v-slot:input>
      <div class="mt-4 text-h6">
        {{ $t('title.editar')}}
      </div>
      <span class="grey--text caption">
        {{ `${$tc('label.valor_original')} ${formatarValor(item.recebimentoAtual)}`}}
      </span>
      <div class="mt-4">
        <v-form lazy-validation ref="form">
          <input-money
            :label="$tc('label.novo_valor_recebimento')"
            v-model="item.recebimentoDefinido"
            :min="0"
            persistent
          />
          <v-text-field
            :label="$tc('label.justificativa')"
            v-model="item.justificativaAlterarRecebimento"
            type="text"
            :rules="[rules.required]"
          >
          </v-text-field>
        </v-form>
      </div>
      <div class="ma-3">
        <v-btn @click="cancelar(item)" text color="grey"> {{ $t('label.cancelar') }} </v-btn>
        <v-btn @click="salvarValorAlterado(item, valorRecebimento)" :disabled="desabilitaSalvar(item)"
            text color="primary"> {{ $t('label.salvar') }}
        </v-btn>
      </div>
    </template>
      <span v-if="indRecebimentoDefinidoAlterado(item)" class="grey--text caption">
        {{ getMoney(item.recebimentoAtual) }}
      </span>
  </v-edit-dialog>
  <v-edit-dialog
    :return-value.sync="item"
    v-else-if="indRecebimentoDefinidoAlterado(item) && statusAprovado(item) && item.justificativaAlterarRecebimento"
    :ref="`${prefixRefNameRecDefinido}${item.idApuracao}`"
  >
    <div class="mt-2">
      {{ getMoney(item.recebimentoDefinido) }}
      <v-icon v-if="!somenteLeitura" class="ml-2">description</v-icon>
    </div>
    <template v-slot:input>
      <div class="mt-4">
        <input-money
          :label="$tc('label.valor_original')"
          v-model="item.recebimentoAtual"
          disabled
          persistent
        />
        <v-row>
          <v-col cols="12" md="10" sm="10">
            <v-textarea
              :label="$tc('label.justificativa')"
              v-model.trim="item.justificativaAlterarRecebimento"
              disabled
              auto-grow
            >
            </v-textarea>
          </v-col>
        </v-row>
      </div>
    </template>
  </v-edit-dialog>
  <td v-else>
    {{ getMoney(item.recebimentoDefinido) }}
  </td>
</template>

<script>

import { getMoney } from '../../../common/functions/helpers';
import InputMoney from '../../../shared-components/inputs/InputMoney';

export default {
  name: 'DialogEditRecebimentoDefinido',
  components: {
    InputMoney,
  },
  props: {
    item: {
      type: Object,
      default: {},
    },
    salvarValorAlterado: {
      type: Function,
      default: () => ({}),
    },
    cancelarEdicaoValorBase: {
      type: Function,
      default: () => ({}),
    },
    indRecebimentoDefinidoAlterado: {
      type: Function,
      default: () => ({}),
    },
  },
  data() {
    return {
      prefixRefNameRecDefinido: 'modalRecDefinido-',
      rules: {
        required: (value) => (!!value || value === 0) || this.$t('message.campo_obrigatorio'),
      },
      valorRecebimento: null,
      justificativa: '',
    };
  },
  methods: {
    getMoney,
    statusAprovado(item) {
      return item.statusApuracao === 'APROVADO';
    },
    desabilitaSalvar(item) {
      return !item.recebimentoDefinido || !item.justificativaAlterarRecebimento || item.recebimentoDefinido < 0;
    },
    datasValidasEdicao(item) {
      return item.dataParcialFormatada && item.dataParcialFormatada.resultado;
    },
    habilitaTooltipJustificativa(item) {
      return item.justificativaAlterarRecebimento && item.justificativaAlterarRecebimento.length > 20;
    },
    indCrescimento(item) {
      return item.indMetaIntervalo;
    },
    formatarValor(valor) {
      if (valor === 0) {
        return '-';
      }
      return this.getMoney(valor) || '-';
    },
    cancelar(item) {
      item.justificativaAlterarRecebimento = this.justificativa;
      item.recebimentoDefinido = this.valorRecebimento;
      const modalRef = this.prefixRefNameRecDefinido.concat(item.idApuracao);
      this.$refs[modalRef].isActive = false;
    },
    guardarValorInicial(item) {
      this.valorRecebimento = item.recebimentoDefinido;
      this.justificativa = item.justificativaAlterarRecebimento;
    },
    verificaValorCrescimento(item) {
      if (item.indMetaIntervalo && item.ultimaFaixa && !item.vlrNdGerado) {
        return getMoney(item.recebimentoDefinido);
      }
      item.recebimentoDefinido = 0;
      return getMoney(item.recebimentoDefinido);
    },
  },
};
</script>
<style scoped>
.InputMoney.v-input input {
    color: black;
}
</style>
